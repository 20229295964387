import 'moment/locale/de';
import * as $ from "jquery";
import moment = require("moment");
import { isNullOrUndefined } from "util"
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import * as DataHelper from "../Typescript/helper/DataHelper";
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/bootstrap.min.css';
import 'selectize/dist/css/selectize.bootstrap3.css'
import { isNullOrEmpty } from '../Typescript/helper/DataHelper';

const alertify = require("alertifyjs");
const selectize = require("selectize");

declare var uk: string;
declare var email: string;
declare var mailingId: string;
declare var salutation: string;

declare var buttonUnsubscribeText: string;
declare var buttonUnsubscribedText: string;

declare var buttonSubmittingText: string;

declare var buttonRevocationText: string;
declare var buttonRevokedText: string;

declare var tooltipTopicOptIn: string;
declare var tooltipTopicOptOut: string;
declare var tooltipTopicOptNeutral: string;

declare var placeholderReason: string;

declare var contactForm: string;

var topicsList = $('#topicsList');
var topicsPreloader = $('#topicsPreloader');

var aboBlocksList = $('#blocksList');
var aboBlocksPreloader = $('#blocksPreloader');


//Feedback
var TextFeedbackFirstname = $('#feedback-firstname');
var TextFeedbackLastname = $('#feedback-lastname');
var TextFeedbackTitle = $('#feedback-title');
var SelectFeedbackSalutation = $('#select-feedback-salutation');
var TextFeedbackEmail = $('#feedback-email');
var SelectFeedbackSubject = $('#select-feedback-subject');
var TextareaFeedbackText = $('#feedback-text');
var BtnFeedbackSubmit = $('#btn-feedback-submit');
var TextFeedbackCompany = $('#feedback-company');

var FeedbackErrorDiv = $('#feedback-error-div');
var FeedbackErrorList = $('#feedback-error-list');

class TopicList {
    public groupName: string;
    public items: Array<TopicItem>;
}

class TopicItem {
    public id: number;
    public name: string;
    public description: string;
    public selection: string;
}


class AboBlockItem {
    public blockName: string;
    public status: string;
}



$(document).ready(function () {

    //Feedback Formular
    SelectFeedbackSubject.selectize({
        allowEmptyOption: false
    });

    SelectFeedbackSalutation.selectize({
        allowEmptyOption: false
    })[0];

    if (!isNullOrEmpty(salutation)) {
        SelectFeedbackSalutation.selectize()[0].selectize.setValue(salutation);
    }

    if (!isNullOrEmpty(email)) {
        TextFeedbackEmail.val(email);
    }

    //Feedback �bermitteln
    $(document).on('click', '#btn-feedback-submit', (event: JQuery.ClickEvent) => {


        var _Salutation = SelectFeedbackSalutation.val();
        var _Title = TextFeedbackTitle.val();
        var _Firstname = TextFeedbackFirstname.val();
        var _Lastname = TextFeedbackLastname.val();
        var _Company = TextFeedbackCompany.val();
        var _Email = TextFeedbackEmail.val();
        var _Subject = SelectFeedbackSubject.val();
        var _Text = TextareaFeedbackText.val();

        FeedbackErrorList.empty();
        var _HasError: boolean = false;

        //Anrede
        if (isNullOrEmpty(_Salutation)) {
            FeedbackErrorList.append('<li>' + "Bitte w�hle eine Anrede aus." + '</li>');
            _HasError = true;
        }

        //Nachname
        if (isNullOrEmpty(_Lastname)) {
            FeedbackErrorList.append('<li>' + "Bitte gebe einen Nachnamen an." + '</li>');
            _HasError = true;
        }

        //Email
        if (isNullOrEmpty(_Email)) {
            FeedbackErrorList.append('<li>' + "Bitte gebe eine E-Mail Adresse an." + '</li>');
            _HasError = true;
        }

        //Betreff
        if (isNullOrEmpty(_Subject)) {
            FeedbackErrorList.append('<li>' + "Bitte w�hlen Sie einen Grund f�r Ihr Anliegen aus." + '</li>');
            _HasError = true;
        }

        //Text
        if (isNullOrEmpty(_Text)) {
            FeedbackErrorList.append('<li>' + "Bitte schreiben Sie uns Ihr Anliegen." + '</li>');
            _HasError = true;
        }

        if (_HasError) {
            FeedbackErrorDiv.prop('hidden', false);

        } else {
            FeedbackErrorDiv.prop('hidden', true);


            var SubmitFeedback = new DataHelper.AjaxData('/Ajax/Submit/Feedback');
            SubmitFeedback.SetData({
                UK: uk,
                Email: email,
                PersonSalutation: _Salutation,
                PersonEmail: _Email,
                PersonFirstname: _Firstname,
                PersonLastname: _Lastname,
                PersonTitle: _Title,
                Text: _Text,
                Company: _Company,
                Title: _Title,
                Subject: _Subject
            });

            SubmitFeedback.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {
                    alertify.success("Ihr Anliegen wurde &uuml;bermittelt.");

                    TextareaFeedbackText.val("");

                } else {
                    alertify.error("Ihr Anliegen konnte nicht &uuml;bermittelt werden.");
                    console.error(data.errorMessage);
                }
            });

        }






    });

    if (!isNullOrUndefined($('#select-mbmedien-optout'))) {
        $('#select-mbmedien-optout').selectize({
            placeholder: placeholderReason,
            allowEmptyOption: true,
        });

        if (!isNullOrUndefined($('#select-mbmedien-optout').selectize()[0])) {
            $('#select-mbmedien-optout').selectize()[0].selectize.clear();
        }
    }

    alertify.set('notifier', 'position', 'top-right');

    var _TopicItems: Array<TopicItem> = new Array<TopicItem>();
    var _TopicList: Array<TopicList> = new Array<TopicList>();

    var _AboBlockList: Array<AboBlockItem> = new Array<AboBlockItem>();


    var _TopicSubscribeSvg = '<svg  data-toggle="tooltip"  title="' + tooltipTopicOptIn + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" /></svg>';
    var _TopicNeutralSvg = '<svg  data-toggle="tooltip"   title="' + tooltipTopicOptNeutral + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11M15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8A1.5,1.5 0 0,1 17,9.5A1.5,1.5 0 0,1 15.5,11M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M9,14H15A1,1 0 0,1 16,15A1,1 0 0,1 15,16H9A1,1 0 0,1 8,15A1,1 0 0,1 9,14Z" /></svg>';
    var _TopicUnsubscribeSvg = '<svg   data-toggle="tooltip"  title="' + tooltipTopicOptOut + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" /></svg>';
    var _TopicLoading = '<div style="vertical-align: unset!; width: unset!; height: unset!"> <svg class="submitting-topic fa-circle-notch fa-w-16 fa-spin" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="circle-notch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg> </div>';

    var _BlockOptinSvg = '<svg  data-toggle="tooltip"  title="' + "Opt-In" + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" /></svg>';
    var _BlockOptoutSvg = '<svg   data-toggle="tooltip"  title="' + "Opt-Out" + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" /></svg>';
    var _BlockLoading = '<div style="vertical-align: unset!; width: unset!; height: unset!"> <svg class="submitting-topic fa-circle-notch fa-w-16 fa-spin" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="circle-notch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg> </div>';

    moment.locale('de');

    //Men�
    $('[data-toggle="offcanvas"]').on('click', function () {
        console.log('yes');
        $('.offcanvas-collapse').toggleClass('open')
    })


    $('#btn-goto-contact-form').on('click', function () {
        $('#nav-tab-contact').click();
    });

    if (!DataHelper.isNullOrEmpty(uk)) {
        LoadTopics();
        LoadAboBlocks();
    }


    //Marketing Abo Block
    function GetBlockSwitches(blockItem: AboBlockItem) {

        return '                <form class="topic-switches" prop-blockname="' + blockItem.blockName + '">' +
            '                   <label>' +
            '                      <input type="radio" name="opt-state" class="subscribe" value="Opt-In" ' + (blockItem.status == "Opt-In" ? "checked" : "") + '/>' +
            _BlockOptinSvg +
            '                    </label>' +
            '                   <label>' +
            '                      <input type="radio" name="opt-state" class="unsubscribe" value="Opt-Out" ' + (blockItem.status == "Opt-Out" ? "checked" : "") + '/>' +
            _BlockOptoutSvg +
            '                    </label>' +
            '                      </form>';
    }

    //Marketing Abo Blocks
    function LoadAboBlocks() {

        console.log('test');

        aboBlocksList.empty();
        var BlockList = new DataHelper.AjaxData('/Ajax/List/AboBlocks/' + uk);
        BlockList.GetRequest().done(data => {

            _AboBlockList = <Array<AboBlockItem>>data;

            console.log(_AboBlockList);

            if (_AboBlockList != null) {


                _AboBlockList.forEach(blockEntry => {

                    aboBlocksList.append(GetAboBlock(blockEntry));

                    aboBlocksList.tooltip({
                        selector: 'svg'
                    });


                });
                aboBlocksPreloader.prop('hidden', true)
                aboBlocksList.prop('hidden', false)
            }
        });


    }

    //Lade berufiche Interessensgebiete
    function LoadTopics() {

        topicsList.empty();
        var TopicList = new DataHelper.AjaxData('/Ajax/List/Topics/' + uk);
        TopicList.GetRequest().done(data => {

            _TopicList = <Array<TopicList>>data;

            console.log(_TopicList);

            if (_TopicList != null) {


                _TopicList.forEach(topicEntry => {


                    var _topicGroup = ' <div class="col-lg-12 dsgvo-group"><h5>' + topicEntry.groupName + '</h5></div>';

                    topicEntry.items.forEach(topicItem => {

                        _TopicItems.push(topicItem);
                        _topicGroup += GetTopic(topicItem);

                    });


                    topicsList.append(_topicGroup);

                    topicsList.tooltip({
                        selector: 'svg'
                    });


                });
                topicsPreloader.prop('hidden', true)
                topicsList.prop('hidden', false)
            }
        });


    }

    //Berufliche Iteressensgebiete Buttons
    function GetTopicSwitches(topic: TopicItem) {

        return '                <form class="topic-switches" prop-topicId="' + topic.id + '">' +
            '                   <label>' +
            '                      <input type="radio" name="opt-state" class="subscribe" value="interesse" ' + (topic.selection == "interesse" ? "checked" : "") + '/>' +
            _TopicSubscribeSvg +
            '                    </label>' +
            '                   <label>' +
            '                      <input type="radio" name="opt-state" class="neutral" value="neutral"  ' + (topic.selection == "neutral" ? "checked" : "") + ' ' + (topic.selection == "desinteresse" ? "disabled='disabled'" : "") + '/>' +
            _TopicNeutralSvg +
            '                    </label>' +
            '                   <label>' +
            '                      <input type="radio" name="opt-state" class="unsubscribe" value="desinteresse" ' + (topic.selection == "desinteresse" ? "checked" : "") + '/>' +
            _TopicUnsubscribeSvg +
            '                    </label>' +
            '                      </form>';
    }


    function GetAboBlock(blockItem: AboBlockItem) {
        var StringContenttopic: string = ' <div class="col-lg-12"><div class="media text-muted pt-2">' +
            '<div class="media-body pb-2 mb-2 small lh-100  border-bottom border-gray">' +
            ' <strong class="d-block text-gray-dark"></strong>' +
            '  <div class="row">' +
            '<div class="col-10 col-sm-6 col-md-10 col-lg-10 col-xs-10">' +
            '               <div class="vertical-center">' +
            '                  <span class="text-item">' + blockItem.blockName + '</span><br>' +
            '<span class="text-description">' + "" + '</span>' +
            '             </div>' +
            '        </div>' +
            '       <div class="col-2 col-sm-6 col-md-2 col-lg-2 col-xs-2">' +
            '          <div class="topic-container">' +
            '             <div class="topics-switches">' +
            GetBlockSwitches(blockItem) +
            '                 </div>' +
            '            </div>' +
            '       </div>' +
            '  </div>' +
            '</div>' +
            '</div>' +
            '</div>';
        return StringContenttopic;
    }


    function GetTopic(topic: TopicItem) {
        var StringContenttopic: string = ' <div class="col-lg-6"><div class="media text-muted pt-5">' +
            '<div class="media-body pb-5 mb-5 small lh-100  border-bottom border-gray">' +
            ' <strong class="d-block text-gray-dark"></strong>' +
            '  <div class="row">' +
            '<div class="col-7 col-sm-6 col-md-8 col-lg-7 col-xs-8">' +
            '               <div class="vertical-center">' +
            '                  <span class="text-item">' + topic.name + '</span><br>' +
            '<span class="text-description">' + topic.description + '</span>' +
            '             </div>' +
            '        </div>' +
            '       <div class="col-5 col-sm-6 col-md-4 col-lg-5 col-xs-4">' +
            '          <div class="topic-container">' +
            '             <div class="topics-switches">' +
            GetTopicSwitches(topic) +
            '                 </div>' +
            '            </div>' +
            '       </div>' +
            '  </div>' +
            '</div>' +
            '</div>' +
            '</div>';
        return StringContenttopic;
    }

    //Sponsor Optout
    $(document).on('click', '[prop-sponsor-customerId]', (event: JQuery.ClickEvent) => {



        var SubmitSponsorOptout = new DataHelper.AjaxData('/Ajax/Submit/SponsorOptout');

        var Button = $(event.target);

        var CustomerId = Button.attr('prop-sponsor-customerid');


        if (Button.text() != buttonRevocationText)
            return;



        Button.removeClass('btn-secondary');
        Button.addClass('btn-submitting');
        Button.html('<i class="fas fa-circle-notch fa-spin"></i> ' + buttonSubmittingText.replace('�', '&Uuml;'));


        SubmitSponsorOptout.SetData({
            UK: uk,
            Email: email,
            CustomerID: CustomerId
        });

        try {
            SubmitSponsorOptout.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-submitted');
                    Button.html('<i class="fa fa-check"></i> ' + buttonRevokedText);

                } else {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-secondary');
                    Button.text(buttonRevocationText);
                    SelectMBmedienOptout.selectize()[0].selectize.enable();
                    TextareaMBmedienOptout.prop('disabled', false);
                    alertify.error("Der Widerruf f�r den Sponsor konnte nicht &uuml;bermittelt werden.");

                }


            });
        } catch (exception) {


            Button.removeClass('btn-submitting');
            Button.addClass('btn-secondary');
            Button.text(buttonRevocationText);
            SelectMBmedienOptout.selectize()[0].selectize.enable();
            TextareaMBmedienOptout.prop('disabled', false);
            alertify.error("Der Widerruf f�r den Sponsor konnte nicht &uuml;bermittelt werden.");

        }
    });



    //Allgemeines OptOut
    $(document).on('click', '#btn-mbmedien-optout', (event: JQuery.ClickEvent) => {


        var SubmitBlacklistOptout = new DataHelper.AjaxData('/Ajax/Submit/BlacklistOptout');

        var Button = $(event.target);

        if (Button.text() != buttonUnsubscribeText)
            return;



        SelectMBmedienOptout.selectize()[0].selectize.disable();
        TextareaMBmedienOptout.prop('disabled', true);

        // Button.prop('disabled', true);

        Button.removeClass('btn-secondary');
        Button.addClass('btn-submitting');
        Button.html('<i class="fas fa-circle-notch fa-spin"></i> ' + buttonSubmittingText.replace('�', '&Uuml;'));


        SubmitBlacklistOptout.SetData({
            UK: uk,
            Email: email,
            Reason: SelectMBmedienOptout.val(),
            ReasonText: TextareaMBmedienOptout.val()
        });

        try {
            SubmitBlacklistOptout.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-submitted');
                    Button.html('<i class="fa fa-check"></i> ' + buttonUnsubscribedText);


                } else {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-secondary');
                    Button.text(buttonUnsubscribeText);
                    SelectMBmedienOptout.selectize()[0].selectize.enable();
                    TextareaMBmedienOptout.prop('disabled', false);
                    alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");

                }


            });
        } catch (exception) {
            //Button.prop('disabled', false);
            Button.removeClass('btn-submitting');
            Button.addClass('btn-secondary');
            Button.text(buttonUnsubscribeText);
            SelectMBmedienOptout.selectize()[0].selectize.enable();
            TextareaMBmedienOptout.prop('disabled', false);
            alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");

        }

    });


    var TextareaMBmedienOptout = $('#textarea-mbmedien-optout');
    var ButtonMBmedienOptout = $('#btn-mbmedien-optout');
    var SelectMBmedienOptout = $('#select-mbmedien-optout');
    ButtonMBmedienOptout.prop('disabled', true);

    //Mailing OptOut Select
    $(document).on('change', '#select-mbmedien-optout', (event: JQuery.ChangeEvent) => {
        var SelectedValue = SelectMBmedienOptout.val();

        ButtonMBmedienOptout.prop('disabled', DataHelper.isNullOrEmpty(SelectedValue));

        if (SelectedValue == "Sonstiges") {
            TextareaMBmedienOptout.parent().prop('hidden', false);
        } else {
            TextareaMBmedienOptout.parent().prop('hidden', true);
        }

    });

    //Mailing OptOut
    $(document).on('click', '#btn-mailing-optout', (event: JQuery.ClickEvent) => {


        console.log('click');


        var SubmitMailingOptout = new DataHelper.AjaxData('/Ajax/Submit/MailingOptout');

        var Button = $(event.target);

        if (Button.text() != buttonUnsubscribeText)
            return;

        //Button.prop('disabled', true);



        Button.removeClass('btn-secondary');
        Button.addClass('btn-submitting');
        Button.html('<i class="fas fa-circle-notch fa-spin"></i> ' + buttonSubmittingText.replace('�', '&Uuml;'));


        SubmitMailingOptout.SetData({
            UK: uk,
            Email: email
        });

        try {
            SubmitMailingOptout.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-submitted');
                    Button.html('<i class="fa fa-check"></i> ' + buttonUnsubscribedText);




                } else {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-secondary');
                    Button.text(buttonUnsubscribeText);


                    alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");
                }


            });
        } catch (exception) {
            Button.removeClass('btn-submitting');
            Button.addClass('btn-secondary');
            Button.text(buttonUnsubscribeText);


            alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");

        }

    });





    //Kunden OptOut
    $(document).on('click', '#btn-customer-optout', (event: JQuery.ClickEvent) => {


        var SubmitMailingOptout = new DataHelper.AjaxData('/Ajax/Submit/CustomerOptout');

        var Button = $(event.target);

        if (Button.text() != buttonUnsubscribeText)
            return;

        //Button.prop('disabled', true);



        Button.removeClass('btn-secondary');
        Button.addClass('btn-submitting');
        Button.html('<i class="fas fa-circle-notch fa-spin"></i> ' + buttonSubmittingText.replace('�', '&Uuml;'));


        SubmitMailingOptout.SetData({
            UK: uk,
            Email: email
        });

        try {
            SubmitMailingOptout.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {

                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-submitted');
                    Button.html('<i class="fa fa-check"></i> ' + buttonUnsubscribedText);




                } else {
                    Button.removeClass('btn-submitting');
                    Button.addClass('btn-secondary');
                    Button.text(buttonUnsubscribeText);


                    alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");
                }


            });
        } catch (exception) {
            //  Button.prop('disabled', false);
            Button.removeClass('btn-submitting');
            Button.addClass('btn-secondary');
            Button.text(buttonUnsubscribeText);


            alertify.error("Das OptOut konnte nicht &uuml;bermittelt werden. Bitte nutzen Sie das Kontaktformular.");

        }

    });


    //Abo Marketing

    $(document).on('change', 'form[prop-blockname] > label  > input', (event: JQuery.ChangeEvent) => {

        var Element = $(event.target).parent();
        var Form = Element.parent();
        var BlockName = Form.attr('prop-blockname');
        var Status = Form.serializeArray()[0].value;
        var InputChildren = Form.children().find('input');

        Element.children().remove('svg');
        Element.append(_TopicLoading);
        InputChildren.prop('disabled', true);

        Element.children('svg').tooltip("hide");
        $(".tooltip").tooltip("hide");

        var SubmitAboBlock = new DataHelper.AjaxData('/Ajax/Submit/AboBlock');

        var _CurrentBlockName = _AboBlockList.filter(function (obj) {
            return (obj.blockName.toString() === BlockName);
        })[0];

        SubmitAboBlock.SetData({
            BlockName: BlockName,
            Status: Status,
            UK: uk,
            Email: email
        });

        try {
            SubmitAboBlock.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {
                    _CurrentBlockName.status = Status;
                } else {
                    alertify.error("Deine Auswahl konnte nicht gespeichert werden.");
                }

                SetForm();

            });
        } catch (exception) {
            SetForm();
            alertify.error("Deine Auswahl konnte nicht gespeichert werden.");
        }

        function SetForm() {
            setTimeout(function () {
                Form.empty();
                Form.append(GetBlockSwitches(_CurrentBlockName));
            }, 250);
        }

        Element.children('svg').tooltip("hide");
        $(".tooltip").tooltip("hide");
    });


    //DGSVO CHANGE
    $(document).on('change', 'form[prop-topicId] > label  > input', (event: JQuery.ChangeEvent) => {

        var Element = $(event.target).parent();
        var Form = Element.parent();
        var TopicId = Form.attr('prop-topicId');
        var Selection = Form.serializeArray()[0].value;
        var InputChildren = Form.children().find('input');

        Element.children().remove('svg');
        Element.append(_TopicLoading);
        InputChildren.prop('disabled', true);

        Element.children('svg').tooltip("hide");
        $(".tooltip").tooltip("hide");

        var SubmitTopic = new DataHelper.AjaxData('/Ajax/Submit/Topic');

        var _CurrentTopic = _TopicItems.filter(function (obj) {
            return (obj.id.toString() === TopicId);
        })[0];

        SubmitTopic.SetData({
            TopicId: TopicId,
            Selection: Selection,
            UK: uk,
            Email: email
        });

        try {
            SubmitTopic.PostRequest().done(data => {
                var Success: boolean = data.success;

                if (Success == true) {
                    _CurrentTopic.selection = Selection;
                } else {
                    alertify.error("Deine Auswahl konnte nicht gespeichert werden.");
                }

                SetForm();

            });
        } catch (exception) {
            SetForm();
            alertify.error("Deine Auswahl konnte nicht gespeichert werden.");
        }

        function SetForm() {
            setTimeout(function () {
                Form.empty();
                Form.append(GetTopicSwitches(_CurrentTopic));
            }, 250);
        }

        Element.children('svg').tooltip("hide");
        $(".tooltip").tooltip("hide");
    });



    $('[data-toggle="tooltip"]').tooltip();


    if (contactForm.toLowerCase() == "true") {
        $('#nav-tab-contact').click();
    }

});